<template>
  <div class="doing-detail" v-loadingx="loading">
    <div class="top-c">
      <div class="info-1">
        考核管理 / 设置
      </div>
      <div class="info-2">
        考核准备: {{appraisalData ? appraisalData.title : ''}} {{appraisalData ? appraisalData.statusText : ''}}
      </div>
      <div class="info-1">{{appraisalData ? appraisalData.remark : ''}}</div>
      <div class="qr-img">
        <qrcode qrWidth="110px" :qrUrl="qrUrl" v-if="qrUrl"></qrcode>
        <span style="color:#333145;font-size: 16px;">扫码进入评选会场</span>
      </div>
    </div>
    <div class="bottom-c">
      <div class="b-l c-item">
        <div class="base-info">
          <div class="base-info-l">
            <img :src="checkImg" alt="">
            {{khstatus === 'doing' ? '考核进行中' : (khstatus === 'waitDoing' ? '考核未开始' : '考核已完成')}}
          </div>
          <div class="base-info-r" v-if="khstatus !== 'over' && appraisalData && appraisalData.type === 'scoring'">
            <a href="javascript:;" style="margin-right: 4px;" class="norm-brn" @click="btnAction(null, {key: 'over'})">{{khstatus === 'waitDoing' ? '开始' : (bkhList['ready'].length === 0 ? '完成' : '下一个')}}</a>
          </div>
        </div>
        <template v-if="khstatus === 'doing' && appraisalData && appraisalData.type === 'scoring'">
          <div class="doing-title">当前被考核人
            <div class="d-t-dian"></div>
          </div>
          <div href="javascript:;" style="position: relative;" class="bkhr-item" @click="showDetail(item)" v-for="item in khzList" :key="item.id">
            <div>被考核人: {{item.userName}}</div>
            <div>代表单位: {{item.represent}}</div>
            <div>备注: {{item.remark}}</div>
            <div class="d-x"></div>
            <!-- <div class="bkhr-btns">
              <fm-btn v-for="btnItem in userbkhActions" :key="btnItem.key" @click="btnAction(item, btnItem)">{{btnItem.label}}</fm-btn>
            </div> -->
          </div>
          <div class="bkhr-item" style="position: relative;" v-if="khzList.length === 0">
            <div>当前无被考核人</div>
            <div class="d-x"></div>
          </div>
          <div class="doing-title">下一个被考核人
            <div class="d-t-dian" style="background: #2F5BEA;"></div>
          </div>
          <div href="javascript:;" class="bkhr-item" @click="showDetail(item)" v-for="item in bkhList['ready']" :key="item.id">
            <div>被考核人: {{item.userName}}</div>
            <div>代表单位: {{item.represent}}</div>
            <div>备注: {{item.remark}}</div>
          </div>
          <div class="doing-note" v-if="bkhList['ready'].length === 0 && appraisalData && appraisalData.type === 'scoring'">
            无下一个被考核人
          </div>
        </template>
      </div>
      <div class="b-r c-item">
        <div class="base-info">
          <div class="base-info-l">
            <img :src="becheckImg" alt="">
            被考核人
          </div>
          <div class="base-info-r">
            <a href="javascript:;" style="margin-right: 4px;" class="norm-brn" @click="clickTitleMenuAll('tj')">统计结果</a><span>|</span>
            <a href="javascript:;" style="margin-right: 4px;" class="norm-brn" @click="clickTitleMenuAll('dfmx')">打分明细</a><span>|</span>
            <a href="javascript:;" style="margin-right: 4px;" class="norm-brn" @click="clickTitleMenuAll('px')">排序</a>
          </div>
        </div>
        <fm-table-new
          ref="main"
          :columns="baseColumns"
          :toolbox="showToolBox"
          export-file-name="被考核人"
          :data-list="showBkhList"
          border="row"
          :stripe="false">
          <table-actions slot="actions" @table-action="tableAction" slot-scope="{ row }" :data="tableActions" :row="row"></table-actions>
        </fm-table-new>
      </div>
    </div>
    <fm-modal
      :value="openDialogMx"
      width="80%"
      :mask-closable="false"
      v-if="openDialogMx"
      theme="mh-withe"
      @cancel="openDialogMx = false">
      <fm-title
        :title-text="(chooseDataDetail ? chooseDataDetail.userName : '') + '得分明细'">
      </fm-title>
      <fm-table-new
        ref="details"
        :toolbox="showToolBox"
        export-file-name="得分明细"
        :columns="mxColumns"
        :data-list="mxList"
        :border="true"
        emptyPlaceholder="未打分"
        @cell-click="clickDf"
        :stripe="false">
      </fm-table-new>
    </fm-modal>
    <fm-form-dialog
      :mask-closable="false"
      form-title="分组明细"
      :open-dialog.sync="openDialogDetail"
      :form-parms="formParmsDetail"
      label-alone
      label-align="left"
      :old-data="chooseDataDetail"
      form-width="800px"
      @formSubmit="formSubmitDetail"
      @handleClose="openDialogDetail = false">
    </fm-form-dialog>
    <fm-form-dialog
      :mask-closable="false"
      form-title="打分"
      :open-dialog.sync="openDialogDf"
      :form-parms="formParmsDf"
      label-alone
      :old-data="chooseDataDetailDf"
      label-align="left"
      form-width="800px"
      @formSubmit="formSubmitDf"
      @handleClose="openDialogDf = false">
    </fm-form-dialog>
    <fm-form-dialog
      :mask-closable="false"
      form-title="下一个"
      submit-btn-label="确定"
      :open-dialog.sync="openDialogNext"
      :form-parms="formParmsNext"
      label-alone
      label-align="left"
      :old-data="nextParm"
      form-width="800px"
      @formSubmit="formSubmitNext"
      @handleClose="openDialogNext = false">
    </fm-form-dialog>
    <be-check-sort @srotChange="loadDetailList" ref="bcsrot" :appraisalId="appraisalId"></be-check-sort>
  </div>
</template>

<script>
import store from '@/store'

import {
  appraisalRequest as request,
  appraisalGroupRequest,
  appraisalGroupDetailRequest,
  appraisalResultDetailRequest
} from '../../api'

import TableActions from '@/components/base/TableActions'
import Qrcode from '@/components/base/qrcode'
import BeCheckSort from './cmp/sort'

export default {
  components: {
    TableActions,
    Qrcode,
    BeCheckSort
  },
  data () {
    let statusMap = {}
    store.getters.appraisalGroupDetailStatusList.forEach((v) => {
      statusMap[v.key] = []
    })
    return {
      checkImg: 'static/images/check/check.png',
      becheckImg: 'static/images/check/becheck.png',
      lm: false,
      checkList: [],
      chooseDataDetailDf: null,
      loading: false,
      qrUrl: '',
      openDialogMx: false,
      openDialogNext: false,
      openDialogDf: false,
      nextParm: null,
      // 考核、被考核、主持、观众
      bkhActions: [{key: 'df', label: '打分', userType: ['check']}, {key: 'dfmx', label: '得分明细', userType: ['host']}],
      // bkhActions: [{key: 'df', label: '打分', userType: ['check']}, {key: 'dfmx', label: '得分明细', userType: ['host']}, {key: 'over', label: '完成', userType: ['host']}],
      // userType: []
      // userType: ['check', 'becheck', 'host', 'other'],
      userType: [],
      appraisalId: null,
      mxList: [],
      bkhList: statusMap,
      appraisalGroupDetailId: null,
      appraisalData: null,
      groups: [],
      chooseData: null,
      openDialog: false,
      chooseDataDetail: null,
      openDialogDetail: false,
      tableActionsDfmx: [{key: 'edit', label: '修改'}, {key: 'del', label: '删除'}],
      openDetail: false
    }
  },
  computed: {
    showToolBox () {
      return ['table-export', 'table-config'].filter(v => this.$authFunsProxy[v]).map(v => v.replace('table-', ''))
    },
    tableActions () {
      return this.appraisalData && this.appraisalData.isInc === 'yes' ? [{key: 'edit', label: '调整'}] : [{key: 'edit', label: '调整'}, {key: 'dfmx', label: '明细'}]
    },
    khstatus () {
      let status = 'doing'
      if (this.bkhList['ready'].length === 0 && this.bkhList['doing'].length === 0 && this.bkhList['over'].length === 0) {
        status = 'waitDoing'
      }
      if (this.bkhList['doing'].length === 0 && this.bkhList['ready'].length === 0 && this.bkhList['wait'].length === 0) {
        status = 'over'
      }
      return status
    },
    userbkhActions () {
      let yzaction = (a) => {
        let data = this.userType.filter(v => a.userType.includes(v))
        return data.length > 0 || a.userType.length === 0
      }
      return this.bkhActions.filter(v => yzaction(v))
    },
    khzList () {
      return this.bkhList['doing']
    },
    showBkhList () {
      let data = []
      let keys = ['doing', 'ready', 'wait', 'over', 'giveup']
      keys.forEach(v => data = data.concat(this.bkhList[v]))
      return data
    },
    groupInfo () {
      let str = '合计' + this.groups.length + '个分组'
      str += '，' + this.groups.filter(v => v.type === 'check').reduce((a,b) => a + b.detailList.length, 0) + '位考核人'
      str += '，' + this.groups.filter(v => v.type === 'becheck').reduce((a,b) => a + b.detailList.length, 0) + '位被考核人'
      str += '，其他人员为' + this.groups.filter(v => !['check', 'becheck'].includes(v.type)).reduce((a,b) => a + b.detailList.length, 0) + '位'
      return str
    },
    baseMxColumns () {
      let data = [{field: 'beUserName', title: '', width: 100}].concat(this.checkList.map((v) => {
        return {
          field: this.lm ? 'lm_' + v.id : v.id,
          title: this.appraisalData.isInc === 'yes' ? '**' : v.userName,
          width: 80
        }
      }))
      return data
    },
    mxColumns () {
      return this.$getTableConfig('details', this.baseMxColumns)
    },
    baseColumns () {
      let data = [{
        field: 'appraisalGroupName',
        title: '分组'
      },
      {
        field: 'userName',
        title: '被考核人'
      },
      {
        field: 'represent',
        title: '代表单位'
      },
      {
        field: 'orderNum',
        title: '顺序'
      },
      {
        field: 'statusText',
        title: '状态'
      },
      {
        field: 'value',
        title: '分值'
      },
      {
        field: 'ranking',
        title: '名次'
      },
      {
        field: 'gotta',
        title: '得奖'
      }]
      let a = [{
        title: '操作',
        slot: 'actions',
        width: 100,
        fixed: true,
        export: false,
        configurable: false
      }]
      return this.userType.includes('host') ? data.concat(a) : data
    },
    columns () {
      return this.$getTableConfig('main', this.baseMxColumns)
    },
    groupTitleMenus () {
      return [{
        key: 'edit',
        label: '修改'
      },
      {
        key: 'del',
        label: '删除'
      }]
    },
    titleMenus () {
      return [{
        key: 'add_group',
        label: '新增分组'
      }]
    },
    formParmsNext: {
      get () {
        let data = [{
          type: 'select',
          label: '下一个被考核人',
          key: 'doing',
          selectDatas: this.showBkhList.map((v) => {
            return {
              key: v.id,
              label: v.userName,
              data: v
            }
          })
        },
        {
          type: 'select',
          label: '下一个准备人',
          key: 'ready',
          selectDatas: this.showBkhList.map((v) => {
            return {
              key: v.id,
              label: v.userName,
              data: v
            }
          })
        }]
        return data
      }
    },
    formParmsDf: {
      get () {
        let data = [{
          type: 'select',
          label: '考核人',
          key: 'appraisalGroupDetailId',
          selectDatas: this.checkList.map((v) => {
            return {
              key: v.id,
              label: v.userName
            }
          }),
          fmDisabled: this.chooseDataDetailDf && true,
          check: {
            required: true
          }
        },
        {
          type: 'select',
          label: '被考核人',
          key: 'beAppraisalGroupDetailId',
          selectDatas: this.showBkhList.map((v) => {
            return {
              key: v.id,
              label: v.userName
            }
          }),
          fmDisabled: this.chooseDataDetailDf && true,
          check: {
            required: true
          }
        },
        {
          type: 'input',
          label: '分值',
          key: 'value'
        },
        {
          type: 'textarea',
          label: '评价',
          key: 'remark'
        }]
        return data
      }
    },
    formParms: {
      get () {
        let data = [{
          type: 'input',
          label: '分组名',
          key: 'name',
          check: {
            required: true
          }
        },
        {
          type: 'textarea',
          label: '说明',
          key: 'remark'
        },
        {
          type: 'select',
          label: '类型',
          key: 'type',
          selectDatas: this.groupTypeList,
          check: {
            required: true
          }
        },
        {
          type: 'textarea',
          label: '记分规则',
          key: 'rule'
        }]
        return data
      }
    },
    formParmsDetail: {
      get () {
        let data = [{
          type: 'select',
          label: '被考核人',
          key: 'workerId',
          selectDatas: this.$store.getters.allWorkerList,
          check: {
            required: true
          }
        },
        {
          type: 'input',
          label: '代表单位',
          key: 'represent'
        },
        {
          type: 'textarea',
          label: '说明',
          key: 'remark'
        },
        {
          type: 'input',
          label: '顺序',
          key: 'orderNum',
          check: {
            rules: {
              type: 'integer'
            }
          }
        },
        {
          type: 'select',
          label: '状态',
          key: 'status',
          selectDatas: this.appraisalGroupDetailStatusList,
          check: {
            required: true
          }
        }]
        return data
      }
    },
    workerId () {
      return this.$store.getters.currentWorkerId
    },
    groupTypeList () {
      return this.$store.getters.appraisalGroupTypeList
    },
    statusList () {
      return this.$store.getters.appraisalStatusList
    },
    appraisalGroupDetailStatusList () {
      return this.$store.getters.appraisalGroupDetailStatusList
    }
  },
  beforeRouteEnter (to, from, next) {
    if (to.query && to.query.id) {
      next()
    } else {
      next(from)
    }
  },
  created () {
    this.appraisalId = this.$router.history.current.query.id
    this.loadUserType()
    this.$store.dispatch('loadAllWorkerList')
    this.loadCheckList()
    this.loadData()
    this.loadDetailList()
  },
  methods: {
    async clickDf (parm) {
      let result = await this.$dialog.confirm({title: parm.column.title + ' 为 ' + parm.row.beUserName + ' 打分', showInput: true, placeholder: '请输入分值'})
      result = result ? result.replaceAll(' ', '') : result
      if (result) {
        if (isNaN(result)) {
          this.$notice.info({
            title: '系统提示',
            desc: '请输入正确分值'
          })
        } else {
          let key = 'd_' + (isNaN(parm.column.field) ? parm.column.field.replace('lm_', '') : parm.column.field)
          if (parm.row[key]) {
            appraisalResultDetailRequest.update(parm.row[key].id, {value: result}).then(() => {
              this.$notice.success({
                title: '系统提示',
                desc: '打分修改完成'
              })
              this.loadMxList()
            })
          } else {
            let newParm = {
              appraisalId: this.appraisalId,
              beAppraisalGroupDetailId: parm.row.id,
              appraisalGroupDetailId: key.replace('d_', ''),
              value: result
            }
            appraisalResultDetailRequest.add(newParm).then(() => {
              this.$notice.success({
                title: '系统提示',
                desc: '打分完成'
              })
              this.loadMxList()
            })
          }
        }
      }
    },
    loadMxList () {
      this.mxList = []
      let parm = {
        appraisalId: this.appraisalId,
        beAppraisalGroupDetailId: this.chooseDataDetail ? this.chooseDataDetail.id : undefined
      }
      appraisalResultDetailRequest.get(parm).then((data) => {
        let dataMap = {}
        data.forEach((v) => {
          dataMap[v.beAppraisalGroupDetailId] = dataMap[v.beAppraisalGroupDetailId] || {}
          dataMap[v.beAppraisalGroupDetailId][v.appraisalGroupDetailId] = v.value
          dataMap[v.beAppraisalGroupDetailId]['lm_' + v.appraisalGroupDetailId] = '**'
          dataMap[v.beAppraisalGroupDetailId]['d_' + v.appraisalGroupDetailId] = v
        })
        this.showBkhList.filter((v2) => !this.chooseDataDetail || (this.chooseDataDetail && this.chooseDataDetail.id === v2.id)).forEach((v) => {
          // this.checkList.forEach((v2) => {
          //   dataItem[v2.id] = null
          // })
          this.mxList.push(Object.assign({
            beUserName: v.userName,
            id: v.id
          }, dataMap[v.id]))
        })
      })
    },
    loadUserType () {
      let parm = {
        appraisalId: this.appraisalId,
        workerId: this.workerId ? undefined : -1
      }
      appraisalGroupDetailRequest.getMy(parm).then((data) => {
        let userType = []
        data.forEach((v) => {
          if (!userType.includes(v.appraisalGroupType)) {
            userType.push(v.appraisalGroupType)
          }
          if (v.appraisalGroupType === 'check') {
            this.appraisalGroupDetailId = v.id
          }
        })
        this.userType = userType
      })
    },
    clickTitleMenuAll (key) {
      this.chooseDataDetailDf = null
      if (['dfmx', 'tj'].includes(key)) {
        this.btnAction(null, {key})
      } else if (key === 'lm') {
        this.lm = !this.lm
      } else if (key === 'px') {
        this.$refs.bcsrot.openDialogPx = true
        this.$refs.bcsrot.loadData()
      }
    },
    btnAction (item, btnItem) {
      this.chooseDataDetail = item
      if (btnItem.key === 'over') {
        this.nextParm = {
          doing: this.bkhList['ready'].length > 0 ? this.bkhList['ready'][0].id : (this.bkhList['wait'].length > 0 ? this.bkhList['wait'].id : null),
          ready: this.bkhList['wait'].length > 0 ? this.bkhList['wait'][0].id : null
        }
        if (this.bkhList['wait'].length === 0 && this.bkhList['ready'].length === 0) {
          this.formSubmitNext({})
        } else {
          this.openDialogNext = true
        }
      } else if (btnItem.key === 'df') {
        this.openDialogDf = true
      } else if (btnItem.key === 'dfmx') {
        this.loadMxList()
        this.openDialogMx = true
        this.$nextTick(() => {
          this.$tableColumnUpdate(this.$refs.details, 'details')
        })
      } else if (btnItem.key === 'tj') {
        this.tj()
      }
    },
    async tj () {
      this.loading = true
      let parm = {
        appraisalId: this.appraisalId
      }
      let dataMap = {}
      let allData = await appraisalResultDetailRequest.get(parm)
      await this.loadGroup()
      let checkWorkers = await this.loadCheckDetailList()
      let groupRate = {}
      this.groups.forEach(v => {
        groupRate[v.id] = v.rule && !isNaN(v.rule) ? Number(v.rule) : 1
      })
      let checkWorkersMap = {}
      checkWorkers.forEach(v => {
        checkWorkersMap[v.id] = {
          rate: groupRate[v.appraisalGroupId],
          all: checkWorkers.filter(v1 => v1.appraisalGroupId === v.appraisalGroupId).map(v2 => v2.id)
        }
      })
      allData.forEach((item) => {
        let value = isNaN(item.value) ? 0 : Number(item.value)
        let dfNum = allData.filter(v => v.beAppraisalGroupDetailId === item.beAppraisalGroupDetailId && checkWorkersMap[item.appraisalGroupDetailId].all.includes(v.appraisalGroupDetailId)).length
        value = value * checkWorkersMap[item.appraisalGroupDetailId].rate / dfNum
        dataMap[item.beAppraisalGroupDetailId] = dataMap[item.beAppraisalGroupDetailId] ? dataMap[item.beAppraisalGroupDetailId].concat([value]) : [value]
      })
      Object.keys(dataMap).forEach((v) => {
        dataMap[v] = dataMap[v].reduce((a, b) => a = a + b, 0)
        dataMap[v] = Number(dataMap[v].toFixed(2))
      })
      let newData = this.showBkhList.map(v => Object.assign({}, v))
      let groupMap = {}
      newData.forEach((v) => {
        v.value = dataMap[v.id] || 0
        if (groupMap[v.appraisalGroupId]) {
          groupMap[v.appraisalGroupId].push(v)
        } else {
          groupMap[v.appraisalGroupId] = [v]
        }
      })
      Object.keys(groupMap).forEach(v => groupMap[v].sort((a,b) => (b.value || -1) - (a.value || -1)))
      let i = 0
      let keys = Object.keys(groupMap)
      while (i < keys.length) {
        let j = 0
        while (j < groupMap[keys[i]].length) {
          let same = j > 0 && groupMap[keys[i]][j].value == groupMap[keys[i]][j - 1].value
          if (groupMap[keys[i]][j].value) {
            groupMap[keys[i]][j].ranking = same ? groupMap[keys[i]][j - 1].ranking : j + 1
            if (j === 0) {
              groupMap[keys[i]][j].gotta = '该组第一名'
            } else if (groupMap[keys[i]][j].ranking === groupMap[keys[i]][0].ranking) {
              groupMap[keys[i]][j].gotta = '该组第一名'
            } else {
              groupMap[keys[i]][j].gotta = ''
            }
          } else {
            groupMap[keys[i]][j].gotta = null
            groupMap[keys[i]][j].ranking = same ? groupMap[keys[i]][j - 1].ranking : j + 1
          }
          await appraisalGroupDetailRequest.update(groupMap[keys[i]][j].id, groupMap[keys[i]][j])
          j += 1
        }
        i += 1
      }
      this.loading = false
      this.loadDetailList()
    },
    showDetail (data) {
      this.chooseDataDetail = data
      this.openDetail = true
    },
    tableActionDfmx (parm) {
      if (parm.action === 'edit') {
        this.chooseDataDetailDf = parm.data
        this.openDialogDf = true
      } else if (parm.action === 'del') {
        this.chooseDataDetailDf = parm.data
        this.delDfmx()
      }
    },
    tableAction (parm) {
      if (parm.action === 'edit') {
        this.chooseDataDetail = parm.data
        this.openDialogDetail = true
      } else if (parm.action === 'dfmx') {
        this.chooseDataDetail = parm.data
        this.loadMxList()
        this.openDialogMx = true
        this.$nextTick(() => {
          this.$tableColumnUpdate(this.$refs.details, 'details')
        })
      }
    },
    getGroupInfo (group) {
      if (!group) {
        return ''
      } else {
        let str = ''
        str += group.rule ? '记分规则:' + group.rule + '<br>' : ''
        str += group.remark ? '说明:' + group.remark : ''
        return str
      }
    },
    async formSubmitNext (data, resolve) {
      this.openDialogNext = false
      this.loading = true
      let allP = []
      if (data.doing) {
        allP.push(appraisalGroupDetailRequest.update(data.doing, {status: 'doing'}))
      }
      if (data.ready) {
        allP.push(appraisalGroupDetailRequest.update(data.ready, {status: 'ready'}))
      }
      this.bkhList.doing.forEach((v) => allP.push(appraisalGroupDetailRequest.update(v.id, {status: 'over'})))
      await Promise.all(allP)
      resolve && resolve()
      this.loading = false
      this.loadDetailList()
    },
    formSubmitDf (data, resolve) {
      if (this.chooseDataDetailDf && this.chooseDataDetailDf.id) {
        appraisalResultDetailRequest.update(this.chooseDataDetailDf.id, data).then(() => {
          this.$notice.success({
            title: '系统提示',
            desc: '打分修改完成'
          })
          this.loadMxList()
          this.openDialogDf = false
          resolve()
        })
        return
      }
      let parm = Object.assign({
        appraisalId: this.appraisalId
      }, data)
      appraisalResultDetailRequest.add(parm).then(() => {
        this.$notice.success({
          title: '系统提示',
          desc: '打分完成'
        })
        this.loadMxList()
        this.openDialogDf = false
        resolve()
      })
    },
    formSubmitDetail (data, resolve) {
      if (this.chooseDataDetail) {
        appraisalGroupDetailRequest.update(this.chooseDataDetail.id, data).then(() => {
          this.$notice.success({
            title: '系统提示',
            desc: '分组明细修改完成'
          })
          this.loadDetailList()
          this.openDialogDetail = false
          resolve()
        })
      } else {
        data.appraisalGroupId = this.chooseData.id
        appraisalGroupDetailRequest.add(data).then(() => {
          this.$notice.success({
            title: '系统提示',
            desc: '分组明细新增成功'
          })
          this.loadDetailList()
          this.openDialogDetail = false
          resolve()
        })
      }
    },
    formSubmit (data) {
      if (this.chooseData) {
        appraisalGroupRequest.update(this.chooseData.id, data).then(() => {
          this.$notice.success({
            title: '系统提示',
            desc: '分组修改完成'
          })
          this.loadGroup()
          this.openDialog = false
        })
      } else {
        data.appraisalId = this.appraisalId
        appraisalGroupRequest.add(data).then(() => {
          this.$notice.success({
            title: '系统提示',
            desc: '分组新增成功'
          })
          this.loadGroup()
          this.openDialog = false
        })
      }
    },
    clickTitleMenu (key) {
      if (key === 'add_group') {
        this.chooseData = null
        this.openDialog = true
      }
    },
    async delDfmx () {
      const result = await this.$dialog.confirm({title: '系统提示', content: '确定删除 ' + this.chooseDataDetailDf.userName + ' 为 ' + this.chooseDataDetailDf.beUserName + ' 打的分吗?'})
      if (result) {
        appraisalResultDetailRequest.del(this.chooseDataDetailDf.id).then(() => {
          this.$notice.info({
            title: '系统提示',
            desc: '打分已删除'
          })
          this.loadMxList()
        })
      }
    },
    async delData () {
      const result = await this.$dialog.confirm({title: '系统提示', content: '确定删除分组 ' + this.chooseData.name + ' 吗?'})
      if (result) {
        appraisalGroupRequest.del(this.chooseData.id).then(() => {
          this.$notice.info({
            title: '系统提示',
            desc: '分组已删除'
          })
          this.loadGroup()
        })
      }
    },
    clickGroupTitleMenu (key, data) {
      this.chooseData = data
      if (key === 'edit') {
        this.openDialog = true
      } else if (key === 'del') {
        this.delData()
      } else if (key === 'add_detail') {
        this.openDialogDetail = true
      }
    },
    loadData () {
      this.loading = true
      let parm = {id: this.appraisalId}
      request.get(parm).then((data) => {
        data.forEach(v => v.statusText = (this.statusList.find(v1 => v1.key === v.status) || {label: ''}).label)
        if (data.length == 0) {
          this.$notice.info({
            title: '系统提示',
            desc: '考核不存在'
          })
        } else {
          this.appraisalData = data[0]
          this.qrUrl = window.location.origin + '/#/wap/' + (this.appraisalData.type === 'scoring' ? 'checking' : this.appraisalData.type) + '?id=' + this.appraisalId
        }
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    checkDataChange () {},
    async loadGroup () {
      let data = await appraisalGroupRequest.get({appraisalId: this.appraisalId})
      data.forEach((v) => {
        v.typeText = (this.groupTypeList.find(v1 => v1.key === v.type) || {label: ''}).label
        v.detailList = []
      })
      this.groups = data
      this.loadDetailList(data)
    },
    async loadCheckDetailList () {
      let parm = {
        appraisalId: this.appraisalId,
        appraisalGroupType: 'check'
      }
      return await appraisalGroupDetailRequest.get(parm)
    },
    loadDetailList () {
      let parm = {
        appraisalId: this.appraisalId,
        appraisalGroupType: 'becheck'
      }
      appraisalGroupDetailRequest.get(parm).then((data) => {
        Object.keys(this.bkhList).forEach(v => this.bkhList[v] = [])
        data.sort((a, b) => (a.orderNum || 1000) - (b.orderNum || 1000))
        data.forEach((v) => {
          v.statusText = (this.appraisalGroupDetailStatusList.find(v1 => v1.key === v.status) || {label: ''}).label
          this.bkhList[v.status].push(v)
        })
      })
    },
    loadCheckList () {
      let parm = {
        appraisalId: this.appraisalId,
        appraisalGroupType: 'check'
      }
      appraisalGroupDetailRequest.get(parm).then((data) => {
        this.checkList = data
      })
    }
  }
}
</script>

<style lang="less">
.doing-detail {
  .fm-table-border-row {
    border-bottom: 0;
  }
  .fm-table-border-row .fm-table-head-cells {
    border-top: 0;
  }
  .fm-table-head-item {
    color: rgba(0,0,0,0.85);
  }
  .fm-table-new.fm-table-norm tr {
    height: 35px;
  }
  tr {
    height: 35px !important;
  }
  .fm-table-cell {
    font-size: 12px;
  }
}
</style>

<style lang="less" scoped>
.doing-title {
  margin-left: 48px;
  font-size: 14px;
  color: #333145;
  margin-bottom: 10px;
  line-height: 20px;
  position: relative;
  .d-t-dian {
    position: absolute;
    left: -20px;
    top: 6px;
    width: 9px;
    height: 9px;
    border-radius: 9px;
    background: #00CCC4;
  }
}
.d-x {
  position: absolute;
  left: -17px;
  width: 1px;
  top: -14px;
  bottom: -30px;
  background: rgba(70,70,70, 0.25);
}
.norm-brn {
  color: #707070;
  font-size: 14px;
}
.base-info {
  height: 24px;
  padding: 18px 16px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .base-info-l {
    display: flex;
    align-items: center;
    color: #333145;
    img {
      width: 24px;
      margin-right: 8px;
    }
  }
  .base-info-r {
    display: flex;
    align-items: center;
    color: #707070;
    font-size: 14px;
  }
}
.top-c {
  position: relative;
  background: #fff;
  margin-bottom: 16px;
  padding: 16px 32px 0;
  height: 160px;
  max-height: 160px;
  .info-1 {
    color: rgba(0,0,0,0.65);
    font-size: 14px;
    line-height: 20px;
  }
  .info-2 {
    font-size: 20px;
    color: #333145;
    line-height: 1;
    margin: 17px 0;
  }
  .qr-img {
    position: absolute;
    bottom: 16px;
    right: 75px;
    span {
      line-height: 1;
    }
  }
}
.bkhr-list-none {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #999;
  font-size: 2rem;
}
.doing-note {
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  color: #999;
}
.bkhr-list-none {
  flex: 1;
}
.bkhr-item {
  border: 0.5px solid rgba(0,0,0,0.25);
  margin: 0 45px 27px 48px;
  border-radius: 8px;
  color: rgba(0,0,0,0.65);
  line-height: 28px;
  font-size: 14px;
  padding: 7px 9px;
  .bkhr-btns {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
  }
}
.b-c-t {
  display: flex;
  flex: 1;
}
.b-r {
  flex: 1;
  margin-right: 16px;
  overflow-y: auto;
}
.c-item {
  background: #fff;
  border-radius: 8px;
}
.b-l {
  width: 440px;
  margin: 0 16px;
  overflow-y: auto;
}
.doing-detail {
  height: 100%;
  width: 100%;
}
.content {
  flex: 1;
  display: flex;
  overflow-x: auto;
}
.group-item {
  min-width: 30%;
  max-width: 50%;
  flex: 1;
  margin: 1rem;
  border: 1px solid #eee;
}
.bottom-c {
  flex: 1;
  height: calc(100% - 176px - 16px);
  display: flex;
}
.n-g {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: #999;
}
.a-remark {
  color: #777;
  margin: 1rem 2rem;
  line-height: 2;
}
</style>